import React from "react";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] mb-6 h-full bg-gradient-to-r from-[#0D4237] to-[#0b4237] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-[500px] object-cover rounded-lg mb-2"
        src="https://i.ibb.co/Wcv8dg1/5-Michelle-White-5.jpg"
        alt="Michelle White"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Michelle White</h3>
        <p className="text-lg font-normal text-gray-400">Software Developer</p>
        <p className="text-base text-gray-400 tracking-wide">
          If you would like to get in touch, please fill out the form provided,
          and I will respond as soon as possible. Thank you for reaching out!
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 m-4">
          <span className="bannerIcon">
            <a
              href="https://www.linkedin.com/in/michellewhite919"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </span>
          <span className="bannerIcon">
            <a
              href="https://github.com/mwhite919"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactLeft;
