import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]"></p>
          <h2 className="text-3xl md:text-4xl font-bold">Education</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Software Developer Mentorship"
            subTitle="Developer Pro (2023)"
            des="Completed Developer Pro Mentorship, receiving personalized guidance to advance technical skills and career goals. Gained proficiency in modern technologies and best practices, with enhanced industry insights."
          />
          <ResumeCard
            title="Software Engineer Fellow with Headstarter.ai "
            subTitle="Headstarter.ai Fellowship(Fall 2024)"
            result=""
            des="Participated in the Headstarter.ai fellowship, where I gained hands-on experience in AI-driven software development and machine learning applications."
          />
          <ResumeCard
            title="BA International Affairs"
            subTitle="Florida State University (2012)"
            des="Graduated with a Bachelors of the Arts in International Affairs with a focus on economics and Portuguse."
          />
          <ResumeCard
            title="International Exchange Student"
            subTitle="Universidade Federal de Viçosa (2011)"
            des="International exchange student studying alongside local students. Focused on economics and Portuguse language study. Also was a member of the university's volleyball team."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
