import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <h2 className="text-3xl md:text-4xl font-bold">Work Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Frontend Developer"
            subTitle="SquaredMade (October 2023-Present)"
            des="Developed and maintained software applications using the MERN stack, collaborating with cross-functional teams to deliver tailored business solutions."
          />
          <ResumeCard
            title="Process Optimization Specialist"
            subTitle="Grünecker Patent Law Firm (2018-2023)"
            des="Improved workflow efficiency implemented solutions that enhanced operational effectiveness and reduced turnaround times for client services."
          />
          <ResumeCard
            title="Account Manager"
            subTitle="Johnson Brothers Liquor Co. (2012-2015)"
            des="Worked as a B2B wine representative managing one of the top territories in Charlotte, NC, producing over $1.3 million in sales revenue annually. "
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <h2 className="text-3xl md:text-4xl font-bold">Certificates</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Certified Scrum Master (CSM)"
            subTitle="Scrum Alliance"
            des="Certified in Scrum methodology to effectively guide teams through Scrum processes, remove obstacles, and foster collaboration for successful project delivery."
          />
          <ResumeCard
            title="Make Design Systems People Want to Use"
            subTitle="Design System Unviersity"
            des="Focused on creating user-centered design systems that enhance usability and engagement. "
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
